import { StiScManifestData } from "@/domain/entities/StiSc";
import {
  convertDecimalWithComma,
  dateToDateString,
  dateToTime,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

let doc: any;

class ConditionsData {
  cell: any;
  key = "";
  isHead = false;
  isBody = false;
  constructor(fields?: Partial<ConditionsData>) {
    Object.assign(this, fields);
  }
}
const isCellColumnDataKey = (params: ConditionsData): boolean => {
  const head = params.cell.row.section === "head";
  const body = params.cell.row.section === "body";

  return (
    params.cell.column.dataKey === params.key &&
    ((params.isHead && head) || (params.isBody && body))
  );
};

const drawCellConditions = (cell: any) => {
  return [
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "totalPieces",
        isHead: true,
        isBody: false
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "grossWeight",
        isHead: true,
        isBody: false
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "volumeWeight",
        isHead: true,
        isBody: false
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "origin",
        isHead: true,
        isBody: false
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "destination",
        isHead: true,
        isBody: false
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "catatan",
        isHead: true,
        isBody: false
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "productType",
        isHead: true
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "productType",
        isBody: true
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "origin",
        isBody: true
      })
    ),
    isCellColumnDataKey(
      new ConditionsData({
        cell,
        key: "destination",
        isBody: true
      })
    )
  ];
};

const drawCellData = (cell: any) => {
  if (drawCellConditions(cell)[0]) {
    cell.row.cells[3].styles = {
      ...cell.row.cells[3].styles,
      halign: "center",
      cellPadding: {
        ...cell.row.cells[3].styles.cellPadding,
        top: 4
      }
    };
  }
  if (drawCellConditions(cell)[1]) {
    cell.row.cells[4].styles = {
      ...cell.row.cells[4].styles,
      halign: "center",
      cellPadding: {
        ...cell.row.cells[3].styles.cellPadding,
        top: 4
      }
    };
  }
  if (drawCellConditions(cell)[2]) {
    cell.row.cells[5].styles = {
      ...cell.row.cells[5].styles,
      halign: "center",
      cellPadding: {
        ...cell.row.cells[3].styles.cellPadding,
        top: 4
      }
    };
  }
  if (drawCellConditions(cell)[3]) {
    cell.row.cells[6].styles = {
      ...cell.row.cells[6].styles,
      halign: "left"
    };
  }
  if (drawCellConditions(cell)[4]) {
    cell.row.cells[7].styles = {
      ...cell.row.cells[7].styles,
      halign: "left"
    };
  }
  if (drawCellConditions(cell)[5]) {
    cell.row.cells[8].styles = {
      ...cell.row.cells[8].styles,
      halign: "left"
    };
  }
  if (drawCellConditions(cell)[6]) {
    cell.row.cells[2].styles = {
      ...cell.row.cells[2].styles,
      cellPadding: {
        ...cell.row.cells[2].styles.cellPadding,
        left: 0.7
      }
    };
  }
  if (drawCellConditions(cell)[7]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[2].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[2].x + 0.7,
          cell.row.cells[2].y +
            cell.row.cells[2].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[3].text.filter((item: any) => item !== "")
                    .length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[2].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellConditions(cell)[8]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[6].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[6].x + 0.7,
          cell.row.cells[6].y +
            cell.row.cells[6].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[3].text.filter((item: any) => item !== "")
                    .length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[6].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellConditions(cell)[9]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[7].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[7].x + 0.7,
          cell.row.cells[7].y +
            cell.row.cells[7].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[3].text.filter((item: any) => item !== "")
                    .length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[7].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
};

const generateManifest = (
  dataHead: StiScManifestData,
  data: Array<any>,
  logo: string
) => {
  doc = new jsPDF("p", "mm", "a4", true);

  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text("STI-SC Manifest", 7, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(7, 25, 195, 0.2, "F");
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");
  doc.text("Nama Sub Consolidator", 7, 35);
  doc.text(":", 40, 35);
  doc.text(ellipsisString(dataHead.stiPartnerName, 70), 42, 35);

  doc.setTextColor("#4d4d4d");
  doc.text("Kota Asal", 7, 40);
  doc.text(":", 40, 40);
  doc.text(`${dataHead.stiCityCode} - ${dataHead.stiCityName}`, 42, 40);

  doc.setTextColor("#4d4d4d");
  doc.text("Tanggal STI-SC", 7, 45);
  doc.text(":", 40, 45);
  doc.text(
    `${dateToDateString(new Date(dataHead.stiCreatedAt))} ${dateToTime(
      new Date(dataHead.stiCreatedAt)
    )} WIB`,
    42,
    45
  );

  // right column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total STT", 110, 35);
  doc.text(":", 143, 35);
  doc.text(`${dataHead.stiTotalStt}`, 145, 35);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Koli", 110, 40);
  doc.text(":", 143, 40);
  doc.text(`${dataHead.stiTotalPieces}`, 145, 40);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Berat Kotor", 110, 45);
  doc.text(":", 143, 45);
  doc.text(
    `${convertDecimalWithComma(dataHead.stiTotalGrossWeight, 2)} Kg`,
    145,
    45
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Berat Dimensi", 110, 50);
  doc.text(":", 143, 50);
  doc.text(
    `${convertDecimalWithComma(dataHead.stiTotalVolumeWeight, 2)} Kg`,
    145,
    50
  );

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  doc.autoTable({
    body: data,
    didParseCell: (cell: any) => {
      if (cell.column.index === 7 || cell.column.index === 8) {
        cell.cell.styles.halign = "center";
      }
    },
    columns: [
      { header: "No.", dataKey: "no" },
      { header: "No. STT", dataKey: "sttNumber" },
      { header: "Produk", dataKey: "productType" },
      { header: "Total\nKoli", dataKey: "totalPieces" },
      { header: "Berat\nKotor", dataKey: "grossWeight" },
      { header: "Berat\nDimensi", dataKey: "volumeWeight" },
      { header: "Origin", dataKey: "origin" },
      { header: "Dest.", dataKey: "destination" },
      { header: "Catatan", dataKey: "catatan" }
    ],
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 55 : 0,
    margin: { top: 7, left: 7, right: 7, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 5, right: 2, bottom: 2, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "top",
      lineColor: "#e0e0e0",
      lineWidth: { top: 0.1, right: 0, bottom: 0.1, left: 0 },
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: {
      8: { cellWidth: 60 }
    },
    willDrawCell: (cell: any) => {
      drawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text("Station Admin", 89, lastY);
  doc.text("(.................................)", 84, lastY + 20);
  doc.text("Kurir", 135, lastY);
  doc.text("(.................................)", 124, lastY + 20);
  doc.text("Operations Supervisor", 164, lastY);
  doc.text("(.................................)", 164, lastY + 20);

  doc.setProperties({
    title: "Manifest STI-SC"
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};
export default generateManifest;

export const mappingDataPdf = (data: StiScManifestData) => {
  return data.stt.map((item, index) => ({
    no: index + 1,
    sttNumber: item.sttNo,
    productType: item.sttProductType,
    totalPieces: item.sttTotalPiece,
    chargeableWeight: convertDecimalWithComma(item.sttChargeableWeight, 2),
    grossWeight: convertDecimalWithComma(item.sttGrossWeight, 2),
    volumeWeight: convertDecimalWithComma(item.sttVolumeWeight, 2),
    origin: item.sttOriginCityId,
    destination: item.sttDestinationCityId,
    catatan: ""
  }));
};
