import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "flex z-20" }
const _hoisted_3 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_4 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_PrintPDF = _resolveComponent("PrintPDF")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getTitle), 1),
            _createVNode(_component_time_range_picker, {
              onSelect: _ctx.setPeriode,
              default: "7 hari terakhir"
            }, null, 8, ["onSelect"])
          ]),
          (_ctx.ableToCreate)
            ? (_openBlock(), _createBlock(_component_lp_button, {
                key: 0,
                onClick: _ctx.goToUpdate,
                title: "Perbarui STI-SC",
                textColor: "white"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.stiSCList.length === 0 && !_ctx.periodeStart && !_ctx.periodeEnd)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            data: "STI-SC"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            columns: _ctx.columns,
            data: _ctx.stiSCList,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            onRequest: _ctx.fetchStiSCList,
            errorCause: _ctx.errorCause,
            totalColumnFreeze: 1,
            paginationStyle: "v3"
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause"]))
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_PrintPDF, { ref: "print" }, null, 512)
    ]),
    (_ctx.isErrorPrintList && !_ctx.isLoadingPrintList)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCausePrintList,
          onCancel: _ctx.closeModalErrorPrintList,
          onTryAgain: () => _ctx.refs.print.print(_ctx.stiScIdToPrint),
          modal: ""
        }, null, 8, ["errorType", "onCancel", "onTryAgain"]))
      : _createCommentVNode("", true)
  ], 64))
}