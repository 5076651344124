
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Notification from "@/app/ui/components/notification/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { capitalize } from "vue";
import generateManifest, { mappingDataPdf } from "../modules/manifest-pdf-v2";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import {
  PieceDetail,
  SttPiecesStiScDetail,
  STTToBeUpdate
} from "@/domain/entities/StiSc";
import {
  convertDecimal,
  convertDecimalAfterComma,
  convertDecimalWithComma,
  storeDatatoLocalStorage,
  removeDataLocalStorage
} from "@/app/infrastructures/misc/Utils";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    Notification
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.setOpenSuccess(false);
      next();
    }
  }
})
export default class CreateFormStiScManifest extends mixins(OutGoingMixins) {
  pagination = {
    page: 1,
    limit: 50
  };
  // delete stt pieces
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";

  get parsedValueSttForDelete() {
    return this.sttNumberToDelete.split("#")[0]?.toUpperCase();
  }
  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";

      // find index no. stt
      const indexSttNumber = this.dataSttToBeUpdate.findIndex((data: any) => {
        return (
          data.sttNumber.toUpperCase() === this.parsedValueSttForDelete ||
          data.sttElexysNumber.toUpperCase() === this.parsedValueSttForDelete
        );
      });

      // no. stt not found
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = "No. STT tidak ditemukan/sudah terhapus";
      } else {
        this.dataSttToBeUpdate.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.parsedValueSttForDelete);
        this.sttNumberSuccessDelete = this.parsedValueSttForDelete;
        this.sttNumberToDelete = "";
        this.showAlertInsufficientPayment = false;
      }
    }
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberListTrash = [];
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  get detailDistrict() {
    return LocationController.districtDetail;
  }
  get typeUser() {
    return capitalize(this.dataProfile.account_type_detail.type);
  }
  get stiScDate() {
    return new Date();
  }

  // handle stt pieces
  isPaid = true;
  isScan = false;
  errorSttNumber = "";
  showAlertInsufficientPayment = false;
  async onValidateSttNumber(val: any) {
    this.isScan = true;
    const splitVal = val.split("#");
    this.form.sttNumber = splitVal[0];
    await this.addSttNumber();
  }
  get isDisableAddSttNumber() {
    return !this.form.sttNumber || this.totalStt >= 500;
  }
  onChangeInputStt() {
    this.isPaid = true;
  }
  onAddSttNumber() {
    this.isScan = false;
    this.addSttNumber();
  }
  async addSttNumber() {
    if (this.isDisableAddSttNumber) {
      return;
    }
    if (this.form.sttNumber) {
      this.errorSttNumber = "";
      await StiSCController.getDetailSttNumber({
        sttNumber: this.form.sttNumber,
        callback: this.addSttPieceToUpdate
      });
    }
  }

  addSttPieceToUpdate(detailSTT: any) {
    if (
      !detailSTT.isAllowUpdateStatus ||
      !detailSTT.piece ||
      !detailSTT.piece?.length
    ) {
      this.isErrorTyping = true;
      this.errorSttNumber = detailSTT.errorMessage || detailSTT.message.id;
      playNotification("error");
    } else {
      const indexCurrentSttNumber = this.dataSttToBeUpdate.findIndex(
        (data: any) => {
          return data.sttNumber === detailSTT.sttNumber;
        }
      );
      if (indexCurrentSttNumber > -1) {
        playNotification("error");
        this.errorSttNumber = "No. STT sudah di input";
        this.isErrorTyping = true;
        return;
      } else {
        this.isPaid = detailSTT?.isPaid;

        this.dataSttToBeUpdate.push(
          new STTToBeUpdate(
            detailSTT.sttNumber,
            detailSTT.sttElexysNumber,
            detailSTT.piece,
            detailSTT.product,
            detailSTT.totalPieces,
            detailSTT.grossWeight,
            detailSTT.volumeWeight,
            detailSTT.origin,
            detailSTT.destination
          )
        );
      }
      playNotification("success");
      this.detailSttNotification = {
        sttNumber: detailSTT.sttNumber,
        sttElexysNumber: detailSTT.sttElexysNumber,
        totalPieces: detailSTT.piece.length,
        ...detailSTT.piece[0]
      };
      this.form.sttNumber = "";
      this.notification = true;
      this.showAlertInsufficientPayment = true;
      // re focus
      const inputStt: any = this.$refs.inputStt;
      inputStt.focus();

      // remove notification after 5 second
      setTimeout(() => {
        this.notification = false;
      }, 15000);
    }
  }
  get totalStt() {
    return this.dataSttToBeUpdate.length;
  }
  get calculatedStt() {
    return this.dataSttToBeUpdate.reduce(
      (a: any, b: any) => {
        return {
          totalPieces: a.totalPieces + b.sttPieces.length,
          totalGrossWeight:
            a.totalGrossWeight +
            b.sttPieces.reduce((c: any, d: any) => {
              return c + d.grossWeight;
            }, 0),
          totalVolumeWeight:
            a.totalVolumeWeight +
            b.sttPieces.reduce((c: any, d: any) => {
              return c + d.volumeWeight;
            }, 0)
        };
      },
      {
        totalPieces: 0,
        totalGrossWeight: 0,
        totalVolumeWeight: 0
      }
    );
  }
  get totalPieces() {
    return this.calculatedStt.totalPieces;
  }
  get totalGrossWeight() {
    return this.calculatedStt.totalGrossWeight;
  }
  get totalVolumeWeight() {
    return this.calculatedStt.totalVolumeWeight;
  }

  // alert popup
  isErrorTyping = false;
  isPartial = false;
  isFailed = false;
  get sttFailedUpdate() {
    return StiSCController.failedStt;
  }
  onResetIsAlreadyExist() {
    this.form.sttNumber = "";
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();
  }
  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: "Perubahan Status STT Berhasil !",
        message: "Status STT berhasil diupdate menjadi STI-SC.",
        image: "image-modal-success"
      };
    // if error typing not found
    if (this.isErrorTyping)
      return {
        onClick: () => this.onResetIsAlreadyExist(),
        title: `${this.isScan ? "Scan" : "Tambah"} No. STT Gagal`,
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // if error typing not found
    if (this.isPartial)
      return {
        onClick: () => this.onCloseSuccess(),
        onDownload: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
        title: "Perubahan Status Berhasil !",
        message:
          "Beberapa status STT berhasil diupdate menjadi STI-SC, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.",
        image: "image-modal-warning"
      };
    // default is failed
    return {
      onClick: () => (this.isFailed = false),
      onDownload: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
      title: "Perubahan Status Gagal !",
      message: "Perubahan status STI-SC gagal dibuat.",
      image: "image-modal-failed"
    };
  }

  onDownloadCsvFailedStt(data: any) {
    this.downloadCsvFailedStt({
      fileName: "sti_sc_stt_failed.csv",
      listStt: data
    });
  }

  // form model
  form = {
    sttNumber: "",
    removeSttNumber: ""
  };

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalAfterComma(value, 1);
  }

  // panel pickup
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op.toggle(event);
  }

  // notification handler
  notification = false;
  detailSttNotification = new PieceDetail(
    "",
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    0,
    "",
    "",
    ""
  );

  // table
  sourceSttFromLocalStorage: any = JSON.parse(
    localStorage.getItem("sti-sc") || "[]"
  );
  get dataSttToBeUpdate() {
    storeDatatoLocalStorage("sti-sc", this.sourceSttFromLocalStorage);
    return this.sourceSttFromLocalStorage;
  }
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-1/12 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (_: SttPiecesStiScDetail, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-2/12 text-left whitespace-nowrap",
        render: (item: SttPiecesStiScDetail) => {
          return `<div class="text-black-lp-300">${item.sttNumber}</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-1/12 text-left whitespace-nowrap",
        render: (item: SttPiecesStiScDetail) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.product}
                  </div>
                </div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-2/12 text-center whitespace-nowrap",
        styleCustom: "justify-center",
        render: (item: SttPiecesStiScDetail) => {
          return `<div class="text-black-lp-300">${item.totalPieces}</div>`;
        }
      },
      {
        name: "Berat Kotor",
        styleHead: "w-2/12 text-left whitespace-nowrap",
        render: (item: SttPiecesStiScDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.grossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Berat Dimensi",
        styleHead: "w-2/12 text-left whitespace-nowrap",
        render: (item: SttPiecesStiScDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.volumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Dest.",
        styleHead: "w-2/12 text-left whitespace-nowrap",
        render: (item: SttPiecesStiScDetail) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.destination}
                  </div>
                </div>`;
        }
      }
    ];
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type.toLowerCase() === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  // save data
  withPrint = false;
  countSuccess = 0;
  countFailed = 0;
  async onSave() {
    this.handleConfirmationSave(false);
    const resp = await StiSCController.onGenerateStiSc({
      sttNumber: this.dataSttToBeUpdate.map((item: any) => {
        return item.sttNumber;
      })
    });
    if (resp.data) {
      if (resp.data.total_stt_failed > 0 && resp.data.total_stt_success === 0) {
        this.countFailed = resp.data.total_stt_failed;
        this.isFailed = true;
        StiSCController.setSttFailed(resp.data.stt_failed);
      } else if (
        resp.data.total_stt_failed > 0 &&
        resp.data.total_stt_success > 0
      ) {
        this.countFailed = resp.data.total_stt_failed;
        this.countSuccess = resp.data.total_stt_success;
        this.isPartial = true;
        StiSCController.setSttFailed(resp.data.stt_failed);
      } else {
        StiSCController.setOpenSuccess(true);
        if (this.withPrint) {
          await this.print(StiSCController.stiScIdToGeneratePdf);
        }
      }
      this.sourceSttFromLocalStorage = [];
    }
    this.handleConfirmationSave(false);
  }

  async print(id: number) {
    MainAppController.showLoading();
    // logo
    const element: any = document;
    const logo = element.getElementById("logo").src;

    const printManifest = await StiSCController.getStiSCManifestDetail({ id });
    if (printManifest) {
      const dataHead = StiSCController.stiSCManifestData;
      const data = mappingDataPdf(dataHead);
      generateManifest(dataHead, data, logo);
    }
    MainAppController.closeLoading();
  }

  pickupAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  get openSuccess(): boolean {
    return StiSCController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    StiSCController.setOpenSuccess(value);
  }
  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push("/sti-sc");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    removeDataLocalStorage("sti-sc");
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }
}
