
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import capitalize from "lodash/capitalize";
import {
  PayloadProcessUpdateSTISC,
  RequestListSTISCSession,
  ResponseSTISCSession,
  StiScProcessData
} from "@/domain/entities/StiSc";
import { BADGE_STYLES } from "@/app/infrastructures/misc/Constants";
import formatInputFloat from "@/app/infrastructures/misc/common-library/FormatInputFloat";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import {
  BadgeResponse,
  ColumnManifestData,
  ErrorMessageEntities,
  HeaderManifestData,
  SectionManifestData
} from "@/domain/entities/MainApp";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { generateManifest2ColumnPotrait } from "@/app/ui/modules/manifest-v2/potrait";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";

@Options({
  components: {
    DetailLayout,
    OverlayPanel
  },
  beforeRouteLeave(to: any, _: any, next: any) {
    if (!this.isSuccessProcess && to.name !== "login") {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.isSuccessProcess = false;
      next();
    }
  }
})
export default class UpdateStiSc extends Vue {
  created() {
    this.onGetSession();
  }
  goBack(): void {
    this.$router.push("/sti-sc");
  }
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  get typeUser() {
    return capitalize(this.dataProfile.account_type_detail.type);
  }
  stiScDate = new Date();

  form = {
    sttNumber: "",
    sttNumberCache: ""
  };
  conditionState = new BadgeResponse();
  arrSttRefExternal: [{ sttNo: string; noRefExternal: string }] = JSON.parse(
    localStorage.getItem("sttRefExternal") || "[]"
  );

  async processData(val: any) {
    const splitVal = val.split("#");
    this.form.sttNumber = splitVal[0];
    this.form.sttNumberCache = splitVal[0];
    this.resetBadge();
    if (this.form.sttNumber) {
      try {
        MainAppController.showLoading();
        const sttNoOthers = await this.handleValidationSTT(this.isSTT);
        const resp = await StiSCController.proccessUpdateSTISC(
          new PayloadProcessUpdateSTISC({
            manifestId: this.stiScData.data.manifestId,
            sttNo: !this.isSTT ? sttNoOthers.sttNo : this.form.sttNumber
          })
        );
        if (!this.stiScData.data.stiSCList.length) {
          this.onGetSession();
        }
        storeDatatoLocalStorage("sttRefExternal", this.arrSttRefExternal);
        this.onUpdateStatus(resp, sttNoOthers.noRefExternal);
      } catch (error) {
        playNotification("error");
        const errMessage: any = error;
        this.handleErrorCase(errMessage, val);
      } finally {
        MainAppController.closeLoading();
      }
    }
  }

  get isSTT() {
    return !!this.form.sttNumber.match(/^\d{1,2}LP\w+$/g);
  }

  async handleScanOthers(str: string) {
    try {
      await TrackingController.trackStt({
        sttNo: str,
        isGetSla: false,
        isSecondCall: false,
        cache: true
      });
      return {
        sttNo: TrackingController.trackingSttData.sttDetail.sttNo,
        noRefExternal: str
      };
    } catch (error) {
      playNotification("error");
      const errMessage: any = error;
      this.handleErrorCase(errMessage, str);
      return;
    }
  }

  async handleValidationSTT(
    isSTT: boolean
  ): Promise<{ sttNo: string; noRefExternal: string }> {
    let sttNoOthers: any = { sttNo: "", noRefExternal: "" };
    if (!isSTT) {
      sttNoOthers = await this.handleScanOthers(this.form.sttNumber);
      this.arrSttRefExternal.push(sttNoOthers);
    } else {
      this.arrSttRefExternal.push({ sttNo: "", noRefExternal: "-" });
    }
    return sttNoOthers;
  }

  handleErrorCase(errMessage: any, val: any) {
    if (errMessage?.response?.status < 500) {
      if (errMessage.response.data.message.id?.match(/ditemukan/gi)) {
        this.conditionState.isNotFound = true;
        return;
      }
      if (errMessage.response.data.message.id.match(/tidak bisa di proses/gi)) {
        this.conditionState.isFailed = true;
        return;
      }
    } else {
      MainAppController.showErrorMessage(
        parsingErrorResponse(errMessage, "Tambah No. STT Gagal!", () => {
          this.processData(val);
          MainAppController.closeErrorMessage();
        })
      );
    }
  }

  async onUpdateStatus(detailSTT: any, noRefExternal: string) {
    if (!detailSTT.sttNumber) {
      playNotification("error");
      this.conditionState.isFailed = true;
    } else {
      playNotification("success");
      this.conditionState.isSuccess = true;
      this.stiScData.data.stiSCList.push(
        new StiScProcessData({
          sttNumber: !noRefExternal ? this.form.sttNumber : detailSTT.sttNumber,
          totalPieces: detailSTT.totalPieces,
          product: detailSTT.product,
          destination: detailSTT.destination,
          grossWeight: detailSTT.grossWeight,
          volumeWeight: detailSTT.volumeWeight,
          status: detailSTT.status,
          noRefExternal: noRefExternal
        })
      );
      this.stiScData.data.totalSTT += 1;
      this.stiScData.data.totalGrossWeight += detailSTT.grossWeight;
      this.stiScData.data.totalVolumeWeight += detailSTT.volumeWeight;
      this.stiScData.data.totalPieces += detailSTT.totalPieces;
      this.form.sttNumber = "";
    }
  }

  resetBadge() {
    this.conditionState.isSuccess = false;
    this.conditionState.isFailed = false;
    this.conditionState.isNotFound = false;
    this.conditionState.isExist = false;
  }
  get isBadgeProcess(): boolean {
    return (
      this.conditionState.isSuccess ||
      this.conditionState.isFailed ||
      this.conditionState.isNotFound
    );
  }
  get badgeProcess(): BadgeResponse {
    if (this.conditionState.isSuccess) {
      return new BadgeResponse({
        title: "Berhasil Diperbarui ke STI-SC",
        message: `${this.form.sttNumberCache} berhasil <br /> diperbarui Status ke STI-SC`,
        icon: "badge-success",
        style: BADGE_STYLES["success"]
      });
    }
    if (this.conditionState.isFailed) {
      return new BadgeResponse({
        title: "Gagal Diperbarui ke STI-SC",
        message: `${this.form.sttNumberCache} gagal <br /> diperbarui Status ke STI-SC`,
        icon: "badge-failed",
        style: BADGE_STYLES["failed"]
      });
    }
    if (this.conditionState.isNotFound) {
      return new BadgeResponse({
        title: "Gagal Diperbarui ke STI-SC",
        message: `STT ${this.form.sttNumberCache} tidak <br /> ditemukan. Cek & coba lagi`,
        icon: "badge-failed",
        style: BADGE_STYLES["failed"]
      });
    }
    if (this.conditionState.isExist) {
      return new BadgeResponse({
        title: "Gagal Diperbarui ke STI-SC",
        message: `No. STT ${this.form.sttNumberCache} sudah di input`,
        icon: "badge-failed",
        style: BADGE_STYLES["failed"]
      });
    }
    return new BadgeResponse();
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-1/20 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (_: StiScProcessData, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.stiScData.pagination.limit *
              (this.stiScData.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-3/20 text-left whitespace-nowrap",
        render: (item: StiScProcessData) => {
          return `<div class="text-black-lp-300">${item.sttNumber}</div>`;
        }
      },
      {
        name: "No. Referensi",
        styleHead: "w-3/20 text-left whitespace-nowrap",
        render: (item: StiScProcessData) => {
          if (this.arrSttRefExternal.length) {
            for (const x of this.arrSttRefExternal) {
              if (item.sttNumber === x.sttNo) {
                return `<div class="text-black-lp-300">${x.noRefExternal}</div>`;
              }
            }
            return `<div class="text-black-lp-300">-</div>`;
          }
        }
      },
      {
        name: "Produk",
        styleHead: "w-3/20 text-left whitespace-nowrap",
        render: (item: StiScProcessData) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.product}
                  </div>
                </div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-3/20 text-center whitespace-nowrap",
        render: (item: StiScProcessData) => {
          return `<div class="text-black-lp-300">${item.totalPieces}</div>`;
        }
      },
      {
        name: "Berat Kotor",
        styleHead: "w-3/20 text-left whitespace-nowrap",
        render: (item: StiScProcessData) => {
          return `<div class="text-black-lp-300">${formatInputFloat(
            item.grossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Berat Dimensi",
        styleHead: "w-3/20 text-left whitespace-nowrap",
        render: (item: StiScProcessData) => {
          return `<div class="text-black-lp-300">${formatInputFloat(
            item.volumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Destinasi",
        styleHead: "w-2/20 text-left whitespace-nowrap",
        render: (item: StiScProcessData) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.destination}
                  </div>
                </div>`;
        }
      },
      {
        name: "Status",
        styleHead: "w-2/20 text-left whitespace-nowrap",
        render: (item: StiScProcessData) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.status}
                  </div>
                </div>`;
        }
      }
    ];
  }

  stiScData: ResponseSTISCSession = new ResponseSTISCSession();

  formatDecimal(value: number): string {
    return formatInputFloat(value, 2);
  }

  // panel process
  withPrint = false;
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs?.op?.toggle(event);
  }
  async onProcessManifest(withPrint: boolean) {
    const refs: any = this.$refs;
    refs?.op?.hide();
    this.withPrint = withPrint;

    MainAppController.showLoading();

    setTimeout(async () => {
      if (!withPrint) {
        try {
          await StiSCController.finishSessionSTISC({
            manifestId: this.stiScData.data.manifestId
          });
        } catch (e) {
          console.warn(e);
        }
        MainAppController.setErrorMessageEntities(
          new ErrorMessageEntities({
            isSuccess: true,
            message: "Berhasil Membuat Manifest STI-SC Baru",
            onClose: this.goBack
          })
        );
        playNotification("success");
        MainAppController.closeLoading();
        MainAppController.showResponseModal();
        this.isSuccessProcess = true;
      } else {
        try {
          const finishSession = await StiSCController.finishSessionSTISC({
            manifestId: this.stiScData.data.manifestId
          });
          await StiSCController.getStiSCManifestDetail({
            id: finishSession.stiScId
          });
          const dataManifest = StiSCController.stiSCManifestData;
          await generateManifest2ColumnPotrait(
            new SectionManifestData({
              featureName: "STI-SC",
              headerDetailData: new HeaderManifestData({
                createdAt: dataManifest.stiCreatedAt,
                totalPiece: dataManifest.stiTotalPieces,
                totalGrossWeight: dataManifest.stiTotalGrossWeight
              }),
              data: dataManifest.stt.map(
                (item: any) =>
                  new ColumnManifestData({
                    sttNo: item.sttNo,
                    totalGrossWeight: item.sttGrossWeight,
                    totalPiece: item.sttTotalPiece
                  })
              ),
              signature: true
            })
          );
          playNotification("success");
          MainAppController.setErrorMessageEntities(
            new ErrorMessageEntities({
              isSuccess: true,
              message: "Berhasil Membuat Manifest STI-SC Baru",
              onClose: this.goBack
            })
          );
        } catch (e) {
          console.warn(e);
        }
        MainAppController.closeLoading();
        MainAppController.showResponseModal();
      }
      this.isSuccessProcess = true;
      storeDatatoLocalStorage("sttRefExternal", []);
    }, 500);
  }

  isSuccessProcess = false;
  openConfirmationLeave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }

  get loadingModal() {
    return MainAppController.loadingModal;
  }

  async onGetSession() {
    try {
      MainAppController.showLoading();
      this.stiScData = await StiSCController.getSTISCSession(
        new RequestListSTISCSession({
          page: this.stiScData.pagination.page
            ? this.stiScData.pagination.page
            : 1,
          limit: this.stiScData.pagination.limit
            ? this.stiScData.pagination.limit
            : 20
        })
      );
      //put your action create session here
      if (this.stiScData.data.manifestId === 0) {
        const resp = await StiSCController.createSTISCSession();
        this.stiScData.data.manifestId = resp.manifestId;
      }
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Mendapatkan List Session!", () => {
          this.onGetSession();
          MainAppController.closeErrorMessage();
        })
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
